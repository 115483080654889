@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  position: relative;
  /* border box */
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-bg {
  background-color: #1e1e2d;
}

.indicator-progress {
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.925rem;
  color: #e11d64;
}

.error-feedback {
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.925rem;
  color: #e11d64;
}

.fv-plugins-message-container {
  margin-top: 0.3rem;
}

.fw-bolder {
  font-weight: 600 !important;
}

.fs-2qx {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fw-bold {
  font-weight: 500 !important;
}

.fs-2 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.fw-bolder {
  font-weight: 600 !important;
}

.link-primary {
  color: #00b2ff;
}

a {
  color: #00b2ff;
  text-decoration: none;
}

a {
  transition: color 0.2s ease, background-color 0.2s ease;
}

.btn.btn-primary {
  color: #ffffff;
  border-color: #00b2ff;
  background-color: #00b2ff;
}

.btn.btn-primary:hover {
  color: #ffffff;
  border-color: #00b2ff;
  background-color: #5bceff;
}

.form-control.form-control-solid {
  background-color: #1b1b29;
  border-color: #1b1b29;
  color: #92929f;
  outline: none;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.form-control.form-control-solid:focus {
  background-color: #10101b;
}

.title-icon {
  color: #a4cafe;
  border: 1px solid #a4cafe;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: 0.3s;
}

.title-icon:hover,
.title-icon:focus,
.title-icon:active,
.title-icon-active {
  color: #1c64f2;
  border: 1px solid #1c64f2;
}

.inherit-height {
  height: inherit;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.h-5px {
  height: 5px !important;
}

#flowbite_tab_item {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/*!* width *!*/
/*::-webkit-scrollbar {*/
/*  width: 8px;*/
/*  height: 8px;*/
/*    border-radius: 4px;*/
/*}*/

/*!* horizontal scroll bar *!*/

/*!* Track *!*/
/*::-webkit-scrollbar-track {*/
/*  background: #f1f1f1;*/
/*}*/

/*!* Handle *!*/
/*::-webkit-scrollbar-thumb {*/
/*  background: #888;*/
/*    border-radius: 4px;*/
/*    transition: background-color 0.3s;*/
/*}*/

/*!* Handle on hover *!*/
/*::-webkit-scrollbar-thumb:hover {*/
/*  background: #555;*/
/*}*/

.hello {
  background-color: #ffffff;
  overflow: hidden;
  /* z-index: -2; */
}

.hello img {
  height: 128px;
  object-fit: contain !important;
}

.left {
  top: -8px;
}

.oppo {
  position: relative;
  /* bottom: 20px; */
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* z-index: -2; */
}

.oppo2 {
  position: relative;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* z-index: 0; */
}

.oppo2 img {
  height: 80px;
  width: auto;
  margin: 0 auto;
  object-fit: contain !important;
}

.oppo img {
  height: 80px;
  min-width: 44px;
  margin: 0 auto;
  object-fit: contain !important;
}

.editableDropDown .Select {
  flex: 1;
}

.overflowStyle {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.searchFix .Select {
  width: 250px;
  height: 45px;
}

.searchFix2 .Select {
  width: 400px;
  height: 45px;
}

.css-1f43avz-a11yText-A11yText,
.css-b62m3t-container {
  width: 300px;
}

.filter-search_dropdown {
  width: 330px;
}

.editabledropdownAsync .css-b62m3t-container,
.editabledropdownAsync .css-b62m3t-container .css-1cw2xkd-control,
.editabledropdownAsync .css-b62m3t-container .css-18n1owg-control {
  /* width: auto !important; */
}

.overlay {
  position: relative;
}

.overlay::after {
  content: "";
  position: absolute;
  background-color: #ebf5ffbd;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.overlay-loader {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}

.table-head {
  @apply leading-[14px] whitespace-nowrap px-[16px] py-[12px]  font-medium text-gray-600  min-w-fit;
}

.table-d-row {
  @apply whitespace-nowrap px-[8px] py-[12px]  h-[inherit];
}

.orangeTg {
  @apply bg-orange-500;
}

.grayTg {
  @apply bg-gray-500;
}
@media (max-height: 1000px) {
  .max-h-custom {
    max-height: 489px;
  }
}

@media (min-height: 1000px) {
  .max-h-custom {
    max-height: 100%;
  }
}
.blueTg {
  @apply bg-blue-500;
}

.break-spaces {
  white-space: break-spaces;
}

.bg-orange-400Tg {
  @apply bg-orange-400;
}

.bg-blue-400Tg {
  @apply bg-blue-400;
}

.bg-teal-400Tg {
  @apply bg-teal-400;
}

.bg-indigo-400Tg {
  @apply bg-indigo-400;
}

.custume-dropdown .css-1f43avz-a11yText-A11yText,
.custume-dropdown .css-b62m3t-container {
  width: 700px !important;
  height: 50px !important;
}

.css-1g4xch6-menu > div > div:hover .custom-container label,
.css-1g4xch6-menu > div > div::selection .custom-container label {
  color: white !important;
}

.css-1g4xch6-menu .custom-container:hover {
  color: white !important;
}

.css-1muo99y-control,
.css-c8ir2w-control {
  height: 50px !important;
}

.css-4uaes7-control {
  height: 50px !important;
}

.css-123oyi9-option:hover .custom-container .descr {
  color: white !important;
}

.css-1dxbl6u-option .custom-container .descr {
  color: white !important;
}

.stack {
  display: flex;
  background-color: transparent;
  padding: 0px 5px;
  border-radius: 20px;
  position: absolute;
  /* left:0; */
  max-width: 500px;
}

.stack > div {
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.stack > div:not(:first-child) {
  margin-left: -20px;
}

.stack > div:hover {
  transform: scale(1.8);
}

.stack:hover > div:not(:first-child) {
  margin-left: 0px;
}

.right {
  display: flex;
  background-color: transparent;
  padding: 0px 5px;
  border-radius: 20px;
  position: absolute;
  /* right:0; */
  max-width: 500px;
}

.right > div {
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.right > div:not(:last-child) {
  margin-right: -20px;
}

.right > div:hover {
  transform: scale(1.8);
}

.right:hover > div:not(:last-child) {
  margin-right: 0px;
}

.scroll-container {
  /* border: 3px solid black; */
  /* border-radius: 5px; */
  overflow: hidden;
}

.scroll-container:hover .scroll-text {
  /* animation properties */
  /* -moz-transform: translateX(100%); */
  /* -webkit-transform: translateX(100%); */
  /* transform: translateX(100%); */

  -moz-animation: my-animation 3s linear infinite;
  -webkit-animation: my-animation 3s linear infinite;
  animation: my-animation 3s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(0%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(0%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.truncated {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.table_purchase thead tr {
  @apply bg-gray-50 border-gray-200 border-b;
}

.table_purchase thead tr th {
  @apply text-gray-600 text-center font-medium whitespace-nowrap;
}

.table_purchase td {
  @apply text-center;
}

.table_purchase tbody td {
  @apply text-sm;
}

input[type="date"] {
  display: inline-block;
  position: relative;
}

input[type="datetime-local"] {
  display: inline-block;
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

/* add a calender icon */
input[type="date"]::before {
  background: url("./utils/date-1-icon.svg") no-repeat center;
  content: "";
  display: inline-block;
  height: 17px;
  right: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
}

input[type="datetime-local"]::before {
  background: url("./utils/date-1-icon.svg") no-repeat center;
  content: "";
  display: inline-block;
  height: 17px;
  right: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
}

input[type="datetime-local"]::before {
  background: url("./utils/date-1-icon.svg") no-repeat center;
  content: "";
  display: inline-block;
  height: 17px;
  right: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
}

input[type="date"].dynamicFormDate::before {
  background: url("./utils/date.svg") no-repeat center;
  background-size: 20px 20px;
  opacity: 0.2;
  content: "";
  display: inline-block;
  height: 24px;
  width: 36px;
  right: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-left: solid 1px rgba(0, 0, 0, 0.7);
}

input[type="datetime-local"].dynamicFormDate::before {
  background: url("./utils/date.svg") no-repeat center;
  background-size: 20px 20px;
  opacity: 0.2;
  content: "";
  display: inline-block;
  height: 24px;
  width: 36px;
  right: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-left: solid 1px rgba(0, 0, 0, 0.7);
}

input[type="date"].dynamicFormDate::-webkit-datetime-edit-day-field {
  opacity: 0.4 !important;
}

input[type="date"].dynamicFormDate.hasValue::-webkit-datetime-edit-day-field {
  opacity: 1 !important;
}

input[type="date"].dynamicFormDate::-webkit-datetime-edit-month-field {
  opacity: 0.4 !important;
}

input[type="date"].dynamicFormDate.hasValue::-webkit-datetime-edit-month-field {
  opacity: 1 !important;
}

input[type="date"].dynamicFormDate::-webkit-datetime-edit-year-field {
  opacity: 0.4 !important;
}

input[type="date"].dynamicFormDate.hasValue::-webkit-datetime-edit-year-field {
  opacity: 1 !important;
}

input[type="date"].dynamicFormDate::-webkit-datetime-edit-text {
  opacity: 0.4 !important;
}

input[type="date"].dynamicFormDate.hasValue::-webkit-datetime-edit-text {
  opacity: 0.4 !important;
}

input[type="datetime-local"].dynamicFormDate::-webkit-datetime-edit-day-field {
  opacity: 0.4 !important;
}

input[type="datetime-local"].dynamicFormDate::-webkit-datetime-edit-hour-field {
  opacity: 0.4 !important;
}

input[type="datetime-local"].dynamicFormDate.hasValue::-webkit-datetime-edit-day-field {
  opacity: 1 !important;
}

input[type="datetime-local"].dynamicFormDate::-webkit-datetime-edit-month-field {
  opacity: 0.4 !important;
}

input[type="datetime-local"].dynamicFormDate.hasValue::-webkit-datetime-edit-month-field {
  opacity: 1 !important;
}

input[type="datetime-local"].dynamicFormDate::-webkit-datetime-edit-year-field {
  opacity: 0.4 !important;
}

input[type="datetime-local"].dynamicFormDate.hasValue::-webkit-datetime-edit-year-field {
  opacity: 1 !important;
}

input[type="datetime-local"].dynamicFormDate::-webkit-datetime-edit-text {
  opacity: 0.4 !important;
}

input[type="datetime-local"].dynamicFormDate.hasValue::-webkit-datetime-edit-text {
  opacity: 0.4 !important;
}

input[type="datetime-local"].dynamicFormDate::-webkit-datetime-edit-minute-field {
  opacity: 0.4 !important;
}

input[type="datetime-local"].dynamicFormDate::-webkit-datetime-edit-day-field {
  opacity: 0.4 !important;
}

.notification-menu {
  position: relative;
}

.notification-count {
  position: absolute;
  height: 15px;
  width: 15px;
  top: 0px;
  right: 0px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-dropdown {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1111111;
  width: 300px;

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.notification-dropdown.show {
  opacity: 1;
  visibility: visible;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  background-color: #cae4ff;
}

.notification-title {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.delete-all-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
}

.notification-item {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c8e0f9;
  font-size: 16px;
  font-weight: 400;
}

.notification-item:last-child {
  border-bottom: none;
}

.delete-button {
  border: none;
  background-color: transparent;
  color: red;
  cursor: pointer;
}

.notification-item {
  opacity: 1;
  transition: opacity 0.3s;
}

.notification-item.deleting {
  opacity: 0;
}

#file_input::-webkit-file-upload-button {
  background-color: #1c64f2;
}

#file_input_dark::-webkit-file-upload-button {
  background-color: #1f2a37;
}

@media (min-width: 640px) {
  .css-1j124qj {
    max-height: calc(100vh - 100px);
  }
}

@media (min-width: 640px) {
  .css-1j124qj {
    width: auto !important;
    border-radius: 3px;
    box-shadow: var(
      --ds-shadow-overlay,
      0 0 0 1px rgba(9, 30, 66, 0.08),
      0 2px 1px rgba(9, 30, 66, 0.08),
      0 0 20px -6px rgba(9, 30, 66, 0.31)
    );
    margin-inline: inherit;
    /* overflow: auto; */
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
}

.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
